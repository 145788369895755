"use strict";

/**
 * This service load and cache all Quarto forms' HTML and JS files.
 * Additionally, it also set RequestID for HTML texts.
 */
app.factory("formFilesCacheService", function ($ocLazyLoad) {
    const htmlFiles = {};
    const htmlElements = {};

    async function loadHtml(htmlFile) {
        if (_.has(htmlFiles, htmlFile)) {
            return await htmlFiles[htmlFile].value;
        } else {
            htmlFiles[htmlFile] = lm.lazyAsync(async () => {
                let html;
                try {
                    html = await $.get(htmlFile);
                } catch (e) {
                    D(() => console.error("cache html http exception", e));
                    throw `unable to load ${htmlFile}, httpStatus: ${_.get(e, "status")} ${_.get(e, "statusText")}`;
                }
                htmlElements[htmlFile] = $(`<div>${html}</div>`);
            });
        }

        try {
            return await htmlFiles[htmlFile].value;
        } catch (err) {
            delete htmlFiles[htmlFile];
            throw err;
        }
    }

    const jsFiles = {};

    async function loadJs(jsFile) {
        if (_.has(jsFiles, jsFile))
            return await jsFiles[jsFile].value;
        else
            jsFiles[jsFile] = lm.lazyAsync(async () => {
                await $ocLazyLoad.load(jsFile)
            });

        try {
            await jsFiles[jsFile].value;
        } catch (err) {
            delete jsFiles[jsFile];
            throw err;
        }
    }

    return {
        /**
         * For same files, we only load it once.
         * @param {string} htmlFile - Cache html file from server, content accessible through `getHtml()` method after available.
         * @param {string} jsFile - Load, initialize and cache JS controllers from server.
         * @returns {Promise<void>} Rejected when html or js files failed to lood / initialize.
         */
        async load(htmlFile, jsFile) {
            const htmlLoader = loadHtml(htmlFile), jsLoader = loadJs(jsFile);
            await htmlLoader;
            await jsLoader;
        },

        /**
         * Get cached html text, with RequestID of Quarto form set.
         * @param {string} htmlFile - HTML file must be successfully loaded in `load()` method.
         * @param {string} requestId - Set this id to attributes "id" and "data-param" of root element, of the loaded html.
         * @returns {null|string} Return null when html is not loaded in `load()` method.
         */
        getHtml(htmlFile, requestId) {
            const htmlElement = _.get(htmlElements, htmlFile);
            if (_.isEmpty(htmlElement)) return null;
            htmlElement.children('[ng-controller]')  // only replace element that has attribute "ng-controller".
                .attr('id', requestId)  // to replace old `id={{strUniqueID}}` implementation.
                .attr('data-param', requestId);  // so that `$attrs.param` has value in controllers.
            return htmlElement.html();
        }
    }
});
